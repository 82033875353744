import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-always',
  template: `
    <p>
      all-always works!
    </p>
  `,
  styles: [
  ]
})
export class AllAlwaysComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.loadScript();
  }

  private loadScript() {
    globalThis._smformid = "Side_bar_all_contacts_-_always";
    globalThis._smformcfg = {"formId":"b7ec8c13-dd64-48e0-bf76-938be1b3ea90","side":"left","width":"600","height":"440","borderColor":"transparent","backgroundColor":"transparent","imageBackground":"transparent","imageUrl":"https://s3-eu-west-1.amazonaws.com/salesmanago/000creator/envelope.png","imageWidth":"50","imageHeight":"40","targetGroup":"ALL","visitStart":"0","visitEnd":"3","codeType":"sidebar","dynamic":"true","countPageViews":false,"autoExpand":false,"showAlways":true};
    (function() {
      var smForm = document.createElement('script'); smForm.type = 'text/javascript'; smForm.async = true;
      smForm.src = 'https://hekate.salesmanago.pl/static/smsidebar.min.js';
      var f = document.getElementsByTagName('script')[0]; f.parentNode.insertBefore(smForm, f);
    })();
  }

}
