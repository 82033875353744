import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-identified-always',
  template: `
    <p>
      identified-always works!
    </p>
  `,
  styles: [
  ]
})
export class IdentifiedAlwaysComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.loadScript();
  }

  private loadScript() {
    globalThis._smformid = "Side_bar_identified_contacts_-_always";
    globalThis._smformcfg = {"formId":"1fb9861e-d576-4ab0-944e-bbafcd82067f","side":"left","width":"500","height":"440","borderColor":"transparent","backgroundColor":"transparent","imageBackground":"transparent","imageUrl":"https://s3-eu-west-1.amazonaws.com/salesmanago/000creator/envelope.png","imageWidth":"50","imageHeight":"40","targetGroup":"IDENTIFIED","visitStart":"0","visitEnd":"3","codeType":"sidebar","dynamic":"true","countPageViews":false,"autoExpand":false,"showAlways":true};
    (function() {
      var smForm = document.createElement('script'); smForm.type = 'text/javascript'; smForm.async = true;
      smForm.src = 'https://hekate.salesmanago.pl/static/smsidebar.min.js';
      var f = document.getElementsByTagName('script')[0]; f.parentNode.insertBefore(smForm, f);
    })();
  }
}
