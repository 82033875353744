import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-page-expired-redirect',
  template: `
    <p>
      landing-page-expired-redirect works!
    </p>
    <div id="salesmanago-Iframe_abea2602-c0e9-487a-9c8b-5c9c59f68380"></div>
  `,
  styles: [
  ]
})
export class LandingPageExpiredRedirectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.loadScript();
  }

  private loadScript() {
    var smIframeParentElem = document.getElementById("salesmanago-Iframe_abea2602-c0e9-487a-9c8b-5c9c59f68380");
    var smIframeElem = document.createElement("iframe");
    smIframeElem.src = "https://hekate.salesmanago.pl/mscf/0fjy7wimjpske0e7/default/landing_expired_11_06_2021_14_00_redirect.htm?" + Date.now() + '"';
    smIframeElem.style.margin = "0";
    smIframeElem.style.padding = "0";
    smIframeElem.width = "500";
    smIframeElem.height = "500";
    smIframeElem.style.overflowY = "hidden";
    smIframeElem.style.overflowX = "hidden";
    smIframeElem.style.background = "#FFFFFF";
    smIframeElem.style.maxWidth = "100%";
    smIframeElem.style.border = "none";
    smIframeParentElem.appendChild(smIframeElem);
  }

}
