<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="collapse navbar-collapse justify-content-center">
    <ul class="nav justify-content-center">
      <li class="nav-item">
        <a class="nav-link" routerLink="/" routerLinkActive="active">
          Strona główna
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/lead-generation" routerLinkActive="active">
          Lead Generation
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/live-chat" routerLinkActive="active">
          live Chat
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/website-personalization" routerLinkActive="active">
          Website Personalization
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/web-push" routerLinkActive="active">
          Web Push
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/war" routerLinkActive="active">
          War
        </a>
      </li>
    </ul>
  </div>
</nav>

<div class="container" style="padding: 30px 0">
  <router-outlet></router-outlet>
</div>
