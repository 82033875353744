import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form',
  template: `
    <div>
      <a class="nav-link link-danger mt-4" routerLink="/lead-generation" routerLinkActive="active">
        Wstecz
      </a>
      <nav class="navbar navbar-expand-lg navbar-light bg-success">
        <div class="collapse navbar-collapse justify-content-center">
          <ul class="nav justify-content-center">
            <li class="nav-item">
              <a class="nav-link link-light" routerLink="/lead-generation/form/basic" routerLinkActive="active">
                Form basic
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-light" routerLink="/lead-generation/form/advanced" routerLinkActive="active">
                Form advanced
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-light" routerLink="/lead-generation/form/html" routerLinkActive="active">
                Form html
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [
  ]
})
export class FormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
