import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";

import { LeadGenerationComponent } from './lead-generation.component';
import { PopupComponent } from './popup/popup.component';
import { BasicComponent } from './popup/basic.component';
import { ImageComponent } from './popup/image.component';
import { AdvancedComponent } from './popup/advanced.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { LandingPageExpiredRedirectComponent } from './landing-page/landing-page-expired-redirect.component';
import { LandingPageExpiredComponent } from './landing-page/landing-page-expired.component';
import { LandingPageBasicComponent } from './landing-page/landing-page-basic.component';
import { FormComponent } from './form/form.component';
import { FormBasicComponent } from './form/form-basic.component';
import { FormAdvancedComponent } from './form/form-advanced.component';
import { IdentifiedAlwaysComponent } from './sidebar/identified-always.component';
import { AnonymousAlwaysComponent } from './sidebar/anonymous-always.component';
import { AllAlwaysComponent } from './sidebar/all-always.component';
import { AllTwoVisitsComponent } from './sidebar/all-two-visits.component';
import { AllTwoPageviewsComponent } from './sidebar/all-two-pageviews.component';
import { FormHtmlComponent } from './form/form-html.component';

const routes: Routes = [
  {
    path: '',
    component: LeadGenerationComponent,
    children: [
      {
        path: 'landing-page',
        component: LandingPageComponent,
        children: [
          {
            path: 'basic',
            component: LandingPageBasicComponent,
          },
          {
            path: 'expired',
            component: LandingPageExpiredComponent,
          },
          {
            path: 'expired-redirect',
            component: LandingPageExpiredRedirectComponent,
          }
        ]
      },
      {
        path: 'pop-up',
        component: PopupComponent,
        children: [
          {
            path: 'basic',
            component: BasicComponent,
          },
          {
            path: 'image',
            component: ImageComponent,
          },
          {
            path: 'advanced',
            component: AdvancedComponent,
          }
        ],
      },
      {
        path: 'sidebar',
        component: SidebarComponent,
        children: [
          {
            path: 'all-always',
            component: AllAlwaysComponent,
          },
          {
            path: 'all-two-pageviews',
            component: AllTwoPageviewsComponent,
          },
          {
            path: 'all-two-visits',
            component: AllTwoVisitsComponent,
          },
          {
            path: 'anonymous-always',
            component: AnonymousAlwaysComponent,
          },
          {
            path: 'identified-always',
            component: IdentifiedAlwaysComponent,
          },
        ],
      },
      {
        path: 'form',
        component: FormComponent,
        children: [
          {
            path: 'basic',
            component: FormBasicComponent,
          },
          {
            path: 'advanced',
            component: FormAdvancedComponent,
          },
          {
            path: 'html',
            component: FormHtmlComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  declarations: [
    LeadGenerationComponent,
    PopupComponent,
    BasicComponent,
    ImageComponent,
    AdvancedComponent,
    SidebarComponent,
    LandingPageComponent,
    LandingPageExpiredRedirectComponent,
    LandingPageExpiredComponent,
    LandingPageBasicComponent,
    FormComponent,
    FormBasicComponent,
    FormAdvancedComponent,
    IdentifiedAlwaysComponent,
    AnonymousAlwaysComponent,
    AllAlwaysComponent,
    AllTwoVisitsComponent,
    AllTwoPageviewsComponent,
    FormHtmlComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule
  ]
})
export class LeadGenerationModule { }
