import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-two-pageviews',
  template: `
    <p>
      all-two-pageviews works!
    </p>
  `,
  styles: [
  ]
})

export class AllTwoPageviewsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.loadScript();
  }

  private loadScript() {
    globalThis._smformid = "Side_bar_all_contacts_-_2_pageviews";
    globalThis._smformcfg = {"formId":"624eb95d-9dcf-4ecd-9c2c-0e58debe332e","side":"left","width":"700","height":"440","borderColor":"transparent","backgroundColor":"transparent","imageBackground":"transparent","imageUrl":"https://s3-eu-west-1.amazonaws.com/salesmanago/000creator/envelope.png","imageWidth":"50","imageHeight":"40","targetGroup":"ALL","visitStart":"1","visitEnd":"3","codeType":"sidebar","dynamic":"true","countPageViews":true,"autoExpand":false,"showAlways":false};
    (function() {
      var smForm = document.createElement('script'); smForm.type = 'text/javascript'; smForm.async = true;
      smForm.src = 'https://hekate.salesmanago.pl/static/smsidebar.min.js';
      var f = document.getElementsByTagName('script')[0]; f.parentNode.insertBefore(smForm, f);
    })();
  }

}
