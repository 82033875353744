import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-two-visits',
  template: `
    <p>
      all-two-visits works!
    </p>
  `,
  styles: [
  ]
})
export class AllTwoVisitsComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
    this.loadScript();
  }

  private loadScript() {
    globalThis._smformid = "Side_bar_all_contacts_-_2_visits";
    globalThis._smformcfg = {"formId":"b172df7a-3ae8-4633-a2e1-a2b79f996a4b","side":"left","width":"800","height":"440","borderColor":"transparent","backgroundColor":"transparent","imageBackground":"transparent","imageUrl":"https://s3-eu-west-1.amazonaws.com/salesmanago/000creator/envelope.png","imageWidth":"50","imageHeight":"40","targetGroup":"ALL","visitStart":"1","visitEnd":"3","codeType":"sidebar","dynamic":"true","countPageViews":false,"autoExpand":false,"showAlways":false};
    (function() {
      var smForm = document.createElement('script'); smForm.type = 'text/javascript'; smForm.async = true;
      smForm.src = 'https://hekate.salesmanago.pl/static/smsidebar.min.js';
      var f = document.getElementsByTagName('script')[0]; f.parentNode.insertBefore(smForm, f);
    })()
  }

}
