import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-advanced',
  template: `
    <p>
      form-advanced works!
    </p>
    <div id="salesmanago-Iframe_df9ac88b-ce65-4866-b06b-33e177e38bff"></div>
  `,
  styles: [
  ]
})
export class FormAdvancedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.loadScript();
  }

  private loadScript() {
    var smIframeParentElem = document.getElementById("salesmanago-Iframe_df9ac88b-ce65-4866-b06b-33e177e38bff");
    var smIframeElem = document.createElement("iframe");
    smIframeElem.src = "https://postlux.salesmanago.com/mscf/wj61u1pxx767spzs/default/Formularz_zaawansowany_1.htm?" + Date.now() + '"';
    smIframeElem.style.margin = "0";
    smIframeElem.style.padding = "0";
    smIframeElem.width = "800";
    smIframeElem.height = "500";
    smIframeElem.style.overflowY = "hidden";
    smIframeElem.style.overflowX = "hidden";
    smIframeElem.style.maxWidth = "100%";
    smIframeElem.style.background = "transparent";
    smIframeElem.style.border = "none";
    smIframeParentElem.appendChild(smIframeElem);
  }


}
