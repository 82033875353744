import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-anonymous-always',
  template: `
    <p>
      anonymous-always works!
    </p>
  `,
  styles: [
  ]
})
export class AnonymousAlwaysComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.loadScript();
  }

  private loadScript() {
    globalThis._smformid = "Side_bar_anonymous_contacts_-_always";
    globalThis._smformcfg = {"formId":"710b4e85-f6f4-437d-9ddf-cb6cb647d2e1","side":"left","width":"500","height":"440","borderColor":"transparent","backgroundColor":"transparent","imageBackground":"transparent","imageUrl":"https://s3-eu-west-1.amazonaws.com/salesmanago/000creator/envelope.png","imageWidth":"50","imageHeight":"40","targetGroup":"ANONYMOUS","visitStart":"0","visitEnd":"3","codeType":"sidebar","dynamic":"true","countPageViews":false,"autoExpand":false,"showAlways":true};
    (function() {
      var smForm = document.createElement('script'); smForm.type = 'text/javascript'; smForm.async = true;
      smForm.src = 'https://hekate.salesmanago.pl/static/smsidebar.min.js';
      var f = document.getElementsByTagName('script')[0]; f.parentNode.insertBefore(smForm, f);
    })();
  }

}
