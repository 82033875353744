import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-basic',
  template: `
    <p>
      form-basic works!
    </p>
    <iframe id="smIframe-gqdkb4j81dz5ky3e" src="https://postlux.salesmanago.com/ms/sid/wj61u1pxx767spzs/gqdkb4j81dz5ky3e.htm" frameborder="0" style="border:none;width:100%"></iframe>
  `,
  styles: [
  ]
})
export class FormBasicComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.loadScript();
  }

  private loadScript() {
    var e,t="smIframe-gqdkb4j81dz5ky3e",n="SM_RESIZE_IFRAME:"+t,i="SM_IFRAME_HEIGHT:"+t+":",r=function(){return e?e:e=document.getElementById(t)};window.addEventListener("resize",function(){r()&&e.contentWindow.postMessage(n,"*")}),window.addEventListener("message",function(t){var n=t.data;try{if(0!==n.indexOf(i))return;r()&&(e.style.height=parseInt(n.replace(i,""))+"px")}catch(o){}})
  }
}
