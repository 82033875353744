import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// RouterModule & Routes activate routing service in Angular
import { RouterModule, Routes } from '@angular/router';

// Include components for which router service to be activated
import { WebPushComponent } from './web-push/web-push.component';
import { WarComponent } from './war/war.component';
import { HomeComponent } from './home/home.component'

// Routes array define component along with the path name for url
const routes: Routes = [
  { path: '', component: HomeComponent },
  {
    path: 'lead-generation',
    loadChildren: () => import('./lead-generation/lead-generation.module')
      .then(m => m.LeadGenerationModule)
  },
  {
    path: 'live-chat',
    loadChildren: () => import('./live-chat/live-chat.module')
      .then(m => m.LiveChatModule)
  },
  {
    path: 'website-personalization',
    loadChildren: () => import('./website-personalization/website-personalization.module')
      .then(m => m.WebsitePersonalizationModule)
  },
  { path: 'web-push', component: WebPushComponent },
  { path: 'war', component: WarComponent }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})

export class AppRoutingModule { }
