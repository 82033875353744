  <nav class="navbar navbar-expand-lg navbar-light bg-warning">
    <div class="collapse navbar-collapse justify-content-center">
      <ul class="nav justify-content-center">
        <li class="nav-item">
          <a class="nav-link link-light" routerLink="/lead-generation/pop-up" routerLinkActive="active">
            Pop up
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link link-light" routerLink="/lead-generation/sidebar" routerLinkActive="active">
            Sidebar
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link link-light" routerLink="/lead-generation/landing-page" routerLinkActive="active">
            Landing page
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link link-light" routerLink="/lead-generation/form" routerLinkActive="active">
            Form
          </a>
        </li>
      </ul>
    </div>
  </nav>

  <router-outlet></router-outlet>

  <p class="mt-xl-5">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum quis elementum nunc. Aliquam ac blandit eros. Mauris vel lacus sapien. Phasellus a urna pulvinar, vulputate felis in, accumsan lacus. Etiam eget purus feugiat, gravida neque eu, faucibus diam. Nam pharetra volutpat pharetra. Duis nec lacinia metus, nec consequat elit. Proin nec risus non urna sodales sagittis. Sed augue dui, bibendum non dui ac, bibendum sollicitudin diam. Cras dui diam, scelerisque vel nibh non, maximus tincidunt ante. Duis blandit euismod dolor non rhoncus.

    Etiam accumsan sodales dolor. Proin euismod enim in mollis vestibulum. Praesent pulvinar dui neque, in vestibulum lorem ullamcorper eu. Sed sed feugiat tortor. Phasellus turpis diam, tincidunt ut pretium sed, blandit eget nulla. Donec nunc sapien, aliquet nec sodales in, rhoncus non enim. Sed cursus sagittis nisi at cursus. Aliquam malesuada turpis quis dolor ultricies, ac blandit neque egestas. Pellentesque tempor sem id lectus convallis ultrices. Donec molestie ante vitae ex maximus laoreet. Fusce ligula libero, viverra eu sem quis, feugiat condimentum arcu.

    Morbi pellentesque lacus consequat, sodales nulla nec, commodo urna. Aenean interdum vitae nulla ut pharetra. Proin in sapien aliquet nibh auctor finibus. Donec tincidunt augue vel nisl vestibulum pharetra. In vel urna porttitor, laoreet nulla nec, luctus ex. Curabitur bibendum dolor condimentum purus feugiat, sed vestibulum odio ullamcorper. Cras sit amet venenatis lectus, sed vehicula sem. Sed quis blandit est. Donec varius eleifend metus vel tincidunt. Vestibulum nec fringilla augue. Nulla nisi libero, rhoncus eget lacinia ut, lacinia sed metus. Quisque non nunc in mi feugiat suscipit sed ut lacus.

    Maecenas luctus urna justo, at tincidunt mi rutrum sit amet. Maecenas congue posuere erat et fringilla. Aliquam ac mi sit amet tortor tristique sodales. Aenean sagittis nibh tellus, sed tempus erat pulvinar at. Donec maximus fringilla ante non imperdiet. Aliquam velit nisi, mattis a libero eu, tristique placerat ante. Fusce congue ligula velit, vel pulvinar enim pellentesque ac. Nullam blandit ante sed sapien dignissim rhoncus. Integer finibus lorem magna, vel tempus neque porttitor eget. Donec nec nisl at leo consequat interdum ut vel tellus. Donec et erat gravida, ultrices elit id, volutpat enim. Duis eget finibus sem. Suspendisse finibus odio quis interdum luctus. In augue enim, fermentum et libero ut, congue imperdiet est.

    Pellentesque molestie diam id mi sollicitudin bibendum. Etiam a nunc non sem sodales hendrerit. Aliquam vehicula porta malesuada. Etiam ultrices dolor in maximus tristique. Suspendisse imperdiet augue vitae maximus vestibulum. Phasellus accumsan dignissim placerat. Vestibulum at condimentum sem.

    Nunc quis dui ultrices massa cursus placerat. Vivamus id orci ipsum. Quisque nec neque et turpis pulvinar rutrum a a ipsum. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Vestibulum porta quam nec iaculis imperdiet. Sed tellus ipsum, elementum eget efficitur nec, dignissim at dui. Donec vel nisl volutpat, euismod ipsum id, lacinia sem. Mauris malesuada faucibus tortor sit amet cursus.

    Etiam purus sem, elementum id magna ac, accumsan facilisis felis. Suspendisse vulputate non quam porttitor commodo. Etiam vel nisl ex. Mauris consequat, velit nec auctor tincidunt, ligula tortor faucibus augue, eget viverra enim nunc a diam. Nunc ac diam nec augue rutrum porta. Sed semper augue at est fermentum, ut aliquam nulla gravida. Donec eu gravida lacus. Maecenas vulputate lacus vitae varius vestibulum. Integer ac congue nunc, id convallis erat.

    Proin sodales varius nisi, vitae dictum sapien. Interdum et malesuada fames ac ante ipsum primis in faucibus. Fusce sit amet lacus ut massa viverra faucibus. Duis et ligula nec lorem interdum pulvinar. Cras eu suscipit odio. Aliquam ultricies leo sit amet molestie auctor. Pellentesque tristique pellentesque purus ac interdum. Nullam eget nisi risus. Ut tincidunt placerat suscipit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed ultrices, nunc a tincidunt ultrices, velit nisl blandit sapien, a lacinia massa est porttitor tortor. Sed maximus erat est.

    Curabitur iaculis justo in lacus tristique mattis. Nulla malesuada vel velit ut pharetra. Morbi mattis ullamcorper semper. Suspendisse varius leo non sagittis sodales. Morbi neque mauris, aliquam et pellentesque vel, scelerisque et mauris. Donec vestibulum ultricies mauris sed tincidunt. Morbi quis interdum nibh, at venenatis augue. In purus odio, sagittis nec commodo eget, interdum pellentesque tortor. Fusce interdum aliquet felis at dapibus. Phasellus magna metus, luctus eu tortor non, volutpat vulputate odio. Donec orci ex, sodales ut euismod sagittis, congue nec diam. Praesent pretium sodales pellentesque.

    Suspendisse ac tellus pulvinar, mattis erat quis, ultricies enim. Integer dictum quam purus. Mauris id mauris luctus, sagittis sapien vitae, lobortis dolor. Nulla in quam ac est tristique mollis at mattis lectus. Duis mi velit, placerat scelerisque erat eu, venenatis malesuada tellus. Quisque vehicula risus ut augue auctor lacinia. Etiam vitae augue rhoncus, sagittis lorem ac, vehicula justo. Integer aliquam lectus in quam consectetur dignissim. Proin quis vulputate felis, eu semper nisl. Donec placerat dolor eu pulvinar eleifend. Quisque vel porta erat. Mauris et neque non nibh hendrerit eleifend vitae eu velit. Nulla facilisi. Morbi sit amet lobortis mauris, sit amet accumsan nisl.

    Sed non scelerisque nulla. Phasellus molestie velit tristique hendrerit ullamcorper. Cras nec orci magna. Aenean in congue tellus, sit amet tincidunt diam. Morbi ultrices erat dolor, id commodo massa luctus sit amet. Phasellus ac aliquam ipsum. Etiam rutrum posuere mi ac faucibus. Suspendisse nisl erat, consectetur efficitur elementum vitae, convallis vel tellus. Curabitur in urna est. Quisque eget dignissim diam. Nulla leo nulla, lacinia at lacus nec, porttitor venenatis mauris. Suspendisse et sollicitudin metus, a condimentum metus. Fusce eget aliquam lectus, eget pellentesque urna. Etiam justo risus, vestibulum eu tempor vel, faucibus vitae ipsum. Sed quis orci eu tellus luctus ullamcorper. Cras suscipit, quam vel interdum convallis, ante est vehicula dui, convallis tempor augue erat sit amet lacus.

    Integer at aliquet ipsum. Vestibulum rhoncus, nisi eget consequat tristique, nisl odio fringilla tellus, a sodales neque urna nec tellus. Nunc et nisl id enim tempor dignissim. Cras maximus sapien nec nunc blandit commodo. Etiam rhoncus porttitor sodales. Praesent accumsan justo ac fermentum euismod. Cras a leo lacinia quam condimentum pharetra. Sed semper eget felis ut consequat. In hac habitasse platea dictumst. Maecenas imperdiet nunc urna, a efficitur tortor interdum at. Sed dapibus egestas sapien. Proin sollicitudin a tellus ac rutrum. Duis tempor tempus egestas.

    Etiam commodo tellus vel diam consectetur, sed ullamcorper turpis egestas. Nullam a nibh bibendum, malesuada augue vitae, suscipit enim. Maecenas eget congue ligula. Fusce iaculis nunc justo, eget maximus arcu placerat quis. Nulla mollis mi eget ultricies hendrerit. In eget eleifend massa, in dictum nibh. Sed pretium diam et arcu pulvinar malesuada. Ut varius nisl eu lorem fringilla, id faucibus lectus porta. Nam accumsan lacinia pharetra.

    Suspendisse id massa in nulla vestibulum porttitor. Etiam vitae tristique massa, sit amet blandit quam. Suspendisse id dolor varius, imperdiet sapien auctor, eleifend sapien. Donec at tellus fringilla, fringilla lorem auctor, gravida risus. Aliquam sit amet nulla vel mauris aliquam pulvinar fringilla fermentum sem. Integer sed euismod lorem, sed ultrices nibh. Cras varius varius ipsum, ac volutpat sapien pharetra vel. Nunc bibendum leo ac blandit hendrerit. Sed commodo in tellus at rhoncus. Sed ut urna nec lectus porttitor porta. Integer faucibus lectus mauris, nec egestas lacus pulvinar a. Integer pharetra ac ipsum ac tempus. Fusce eget porta risus, eget mattis eros. Curabitur mattis lacus eget urna convallis, auctor ultrices enim euismod. Nullam justo sapien, egestas vitae laoreet non, euismod mollis est.

    Sed porta turpis leo, vitae lacinia eros euismod id. Integer porta fringilla risus pretium malesuada. Maecenas dignissim quis purus ac dignissim. Aenean sed lectus id augue dictum laoreet. Ut pellentesque felis tempus mauris lacinia blandit et at eros. Curabitur efficitur efficitur sem sit amet iaculis. Suspendisse pulvinar mollis metus, sed interdum justo pretium non. Etiam rhoncus lorem ante, vel dictum libero dignissim vel. Mauris sodales quam quis nibh elementum pellentesque. Maecenas varius est eu augue varius, a varius augue pharetra. Cras egestas dolor vitae elit lobortis viverra. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.

    Nullam diam tortor, ultricies nec sollicitudin id, finibus non elit. Nulla eu magna quis magna congue maximus. In elit ligula, rhoncus fringilla lacus et, pulvinar vulputate eros. Nulla sit amet nisl nisi. Phasellus odio ex, feugiat ut arcu a, cursus facilisis massa. Nulla ornare vulputate magna non sodales. Morbi vel lorem volutpat, efficitur risus in, pharetra nunc. Aliquam erat volutpat. Quisque sit amet enim mi. Nulla non ipsum vel justo vestibulum bibendum sit amet nec quam. Nam a elementum justo, in tincidunt ipsum. Pellentesque vulputate dictum purus, in semper quam maximus vitae. Fusce sit amet sollicitudin mi. Cras fermentum libero eu lobortis auctor. Suspendisse venenatis est ullamcorper massa malesuada, a auctor enim luctus.

    Vestibulum fermentum egestas nisi et porttitor. Mauris tempus enim turpis, vitae mattis odio sagittis ut. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus interdum, elit ornare pellentesque accumsan, lacus velit interdum libero, ac dapibus risus erat eget nisl. Aliquam a metus dictum, iaculis urna ut, dictum dolor. Ut ullamcorper tellus nulla, consectetur bibendum magna elementum sed. Etiam malesuada accumsan ante, pharetra accumsan nunc egestas sed. Mauris id neque vitae lorem fermentum tempus eu in lacus. Fusce sodales lobortis est, nec cursus dui fringilla imperdiet. Phasellus hendrerit lorem et tellus venenatis, in imperdiet leo ornare. Pellentesque quis metus ut sapien scelerisque ultrices. Duis dui neque, maximus ut rhoncus id, ullamcorper vel lacus. Pellentesque ultrices enim eu commodo ultrices.

    Duis eu purus justo. In a porttitor sem, et aliquet leo. Ut eu ante sed nulla iaculis tempus a ut ipsum. Sed eget tincidunt metus, et tincidunt purus. Etiam magna erat, mollis eget consectetur molestie, luctus eget tortor. Nam et erat nisl. In hac habitasse platea dictumst. Aliquam sed ullamcorper tortor. Integer massa odio, condimentum nec neque vitae, vestibulum commodo massa.

    Suspendisse consectetur ullamcorper ante, id accumsan neque lacinia in. Proin lacinia sodales justo, non finibus enim imperdiet eget. Duis lacus risus, eleifend ut metus sit amet, hendrerit auctor mi. Pellentesque maximus blandit mauris in varius. Fusce ac luctus metus. Phasellus id urna sit amet mauris faucibus cursus sit amet vitae eros. Maecenas a sapien ac eros consequat cursus.

    Quisque quis erat metus. Vivamus volutpat, nunc id hendrerit pretium, ante erat convallis lacus, at laoreet metus purus aliquet turpis. Quisque magna justo, tempus vel metus quis, posuere malesuada leo. Maecenas congue, leo sed scelerisque varius, enim massa ultrices neque, et luctus ipsum mauris ut metus. Phasellus non nunc enim. Aliquam erat volutpat. Sed non facilisis augue. In sollicitudin sit amet elit at efficitur. Maecenas rutrum feugiat malesuada. In accumsan vulputate venenatis. Nullam et nisi mollis, dictum nisi eget, venenatis tortor. Curabitur finibus mollis velit, non interdum velit.
  </p>
