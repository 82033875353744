import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lead-generation',
  templateUrl: './lead-generation.component.html',
  styleUrls: ['./lead-generation.component.scss']
})
export class LeadGenerationComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
