import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-page',
  template: `
    <div>
      <a class="nav-link link-danger mt-4" routerLink="/lead-generation" routerLinkActive="active">
        Wstecz
      </a>
      <nav class="navbar navbar-expand-lg navbar-light bg-success">
        <div class="collapse navbar-collapse justify-content-center">
          <ul class="nav justify-content-center">
            <li class="nav-item">
              <a class="nav-link link-light" routerLink="/lead-generation/landing-page/basic" routerLinkActive="active">
                Landing page basic
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-light" routerLink="/lead-generation/landing-page/expired" routerLinkActive="active">
                Landing page expired
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-light" routerLink="/lead-generation/landing-page/expired-redirect" routerLinkActive="active">
                Landing page expired redirect
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div id="salesmanago-Iframe_7d22feb1-ad28-4960-bba8-3768dd164a04"></div>
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [
  ]
})
export class LandingPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.loadScript();
  }

  private loadScript() {
    var smIframeParentElem = document.getElementById("salesmanago-Iframe_7d22feb1-ad28-4960-bba8-3768dd164a04");
    var smIframeElem = document.createElement("iframe");
    smIframeElem.src = "https://postlux.salesmanago.com/mscf/wj61u1pxx767spzs/default/Landing_Page_1.htm?" + Date.now() + '"';
    smIframeElem.style.margin = "0";
    smIframeElem.style.padding = "0";
    smIframeElem.width = "800";
    smIframeElem.height = "500";
    smIframeElem.style.overflowY = "hidden";
    smIframeElem.style.overflowX = "hidden";
    smIframeElem.style.background = "#ffffff";
    smIframeElem.style.maxWidth = "100%";
    smIframeElem.style.border = "none";
    smIframeParentElem.appendChild(smIframeElem);
  }

}
