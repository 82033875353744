import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  template: `
    <div>
      <a class="nav-link link-danger mt-4" routerLink="/lead-generation" routerLinkActive="active">
        Wstecz
      </a>
      <nav class="navbar navbar-expand-lg navbar-light bg-success">
        <div class="collapse navbar-collapse justify-content-center">
          <ul class="nav justify-content-center">
            <li class="nav-item">
              <a class="nav-link link-light" routerLink="/lead-generation/sidebar/all-always" routerLinkActive="active">
                Sidebar all-always
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-light" routerLink="/lead-generation/sidebar/all-two-pageviews" routerLinkActive="active">
                Sidebar all-two-pageviews
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-light" routerLink="/lead-generation/sidebar/all-two-visits" routerLinkActive="active">
                Sidebar all-two-visits
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-light" routerLink="/lead-generation/sidebar/anonymous-always" routerLinkActive="active">
                Sidebar anonymous-always
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link link-light" routerLink="/lead-generation/sidebar/identified-always" routerLinkActive="active">
                Sidebar identified-always
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [
  ]
})
export class SidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.loadScript();
  }

  private loadScript() {
    globalThis._smformid = "Sidebar_1";
    globalThis._smformcfg = {"formId":"8c04ad04-3344-4366-8773-7e953ee730d9","side":"left","width":"800","height":"440","borderColor":"transparent","backgroundColor":"transparent","imageBackground":"transparent","openImageUrl":"https://s3-eu-west-1.amazonaws.com/salesmanago/000creator/envelope.png","imageWidth":"50","imageHeight":"40","imageUrl":"https://s3-eu-west-1.amazonaws.com/salesmanago/000creator/envelope.png","targetGroup":"ALL","visitStart":"0","visitEnd":"3","codeType":"sidebar","dynamic":"true","countPageViews":false,"showAlways":true};
    (function() {
      var smForm = document.createElement('script'); smForm.type = 'text/javascript'; smForm.async = true;
      smForm.src = "https://postlux.salesmanago.com/static/smsidebar.min.js";
      var f = document.getElementsByTagName('script')[0];
      f.parentNode.insertBefore(smForm, f);
    })();
  }

}
