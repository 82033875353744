import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-form-html',
  template: `
    <p>
      form-html works!
    </p>
    <iframe id="salesmanagoIframe" style="margin: 0; padding: 0; width:370px; height:315px; overflow-y:hidden; overflow-x:hidden; border:none; background:#ffffff;" src="https://postlux.salesmanago.com/cf/wj61u1pxx767spzs/Formularz_z_DEMO_.htm"></iframe>
  `,
  styles: [
  ]
})
export class FormHtmlComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
